import { getAccessToken } from '@/services/axios-instance';
import { Operations } from '../enums/Operations';
import { UIOperations } from '../enums/UIOperations';
import { getCampusId } from '../helpers/campus';
import { parseAuthToken } from '../helpers/token';
import { hasPermission } from '../helpers/userPermission';

interface RolePermissions {
  name: string;
  permissions: (Operations | UIOperations)[];
}

export interface UserPermissionsType {
  [key: string]: RolePermissions;
}

export const ALL_CAMPUSES_SUFFIX = '_all-campuses';

export const addAllCampusesPermission = (permission: string) => {
  return `${permission}${ALL_CAMPUSES_SUFFIX}` as string;
};

export const showAllCampusesOption = (permission: Operations) =>
  hasPermission(addAllCampusesPermission(permission));

export const getCampusSearchValue = (permission: Operations) =>
  hasPermission(addAllCampusesPermission(permission)) ? 'all' : getCampusId();

// export const Roles: UserPermissionsType = JSON.parse(JSON.stringify(RolesJSON));

export const getStrongestRole = (): number => {
  const parsedAuthToken = parseAuthToken(getAccessToken());
  const isE12Admin = Boolean(parsedAuthToken.managementRoles.length > 0);

  if (!isE12Admin) {
    const campusID = getCampusId();
    const campusRoles = parsedAuthToken.roles.filter(
      (role) => role.campusID === campusID
    );

    if (campusRoles.length > 0) {
      return Math.min(...campusRoles.map((role) => Math.min(...role.values)));
    }
  }
  return Math.min(...parsedAuthToken.managementRoles);
};

export const ROLE_MAP = new Map<string, number[]>([
  ['E12', [1, 2, 3]],
  ['FOUNDER', [5, 6]],
  ['MANAGEMENT', [5, 6, 22, 23]],
  ['ACCOUNTING', [16, 17, 18, 29]],
  ['IK', [22, 23]],
]);

export const hasRole = (role: number, roleName: string): boolean => {
  const roleIds = ROLE_MAP.get(roleName);
  return roleIds ? roleIds.includes(role) : false;
};

export const isE12Admin = (role: number) => hasRole(role, 'E12');
export const isManagementRole = (role: number) => hasRole(role, 'MANAGEMENT');
export const isFounderRole = (role: number) => hasRole(role, 'FOUNDER');
export const isAccountingRole = (role: number) => hasRole(role, 'ACCOUNTING');
export const isIKRole = (role: number) => hasRole(role, 'IK');
