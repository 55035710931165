import TAG_TYPES from '@/utils/constants/tagTypes';
import { baseApi } from '../baseApi';
import { IUploadFileResponse } from './types';

export const filesApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    uploadFile: builder.mutation<{ path: string }, IUploadFileResponse>({
      query: ({ campusID, ...params }) => ({
        url: `/files`,
        method: 'POST',
        data: params,
        headers: {
          CampusID: campusID,
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: [TAG_TYPES.FILE],
    }),
  }),
});

export const { useUploadFileMutation } = filesApi;
