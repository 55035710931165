import { Dialog } from 'primereact/dialog';
import { imageWithCDNUrl } from '@/utils/helpers/image';

interface IFileDialogProps {
  isModalOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
  selectedFileURL: string;
}

const getFileType = (url: string) => {
  return url.split('.').pop()?.toLowerCase();
};

const FileDialog = ({
  isModalOpen,
  toggleModal,
  selectedFileURL,
}: IFileDialogProps) => {
  const fileType = getFileType(selectedFileURL);

  const renderFilePreview = () => {
    const filePreviewMap: { [key: string]: JSX.Element } = {
      pdf: (
        <iframe
          className="w-full h-full"
          title="pdf-file-preview"
          src={imageWithCDNUrl(selectedFileURL)}
        />
      ),
      jpg: (
        <img
          className="w-full h-full object-contain"
          alt="file-preview"
          loading="eager"
          src={imageWithCDNUrl(selectedFileURL)}
        />
      ),
      jpeg: (
        <img
          className="w-full h-full object-contain"
          alt="file-preview"
          loading="eager"
          src={imageWithCDNUrl(selectedFileURL)}
        />
      ),
      png: (
        <img
          className="w-full h-full object-contain"
          alt="file-preview"
          loading="eager"
          src={imageWithCDNUrl(selectedFileURL)}
        />
      ),
      mp3: (
        <div className="flex flex-col h-full">
          <div className="flex justify-center items-center h-full">
            <i className="icon-[material-symbols-light--library-music-outline] size-52" />
          </div>
          <audio
            className="w-full"
            controls
            src={imageWithCDNUrl(selectedFileURL)}
          />
        </div>
      ),
    };

    return (
      filePreviewMap[fileType as string] || (
        <div className="flex justify-center items-center h-full">
          <p>Bu dosya önizlenemiyor. Lütfen indirin.</p>
        </div>
      )
    );
  };

  return (
    <Dialog
      visible={isModalOpen}
      draggable={false}
      className="w-3/4 h-full"
      onHide={() => toggleModal(false)}
      maximizable
    >
      {renderFilePreview()}
    </Dialog>
  );
};

export default FileDialog;
