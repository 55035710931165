import { nanoid } from '@reduxjs/toolkit';
import { toast, ToastOptions as ReactToastOptions } from 'react-toastify';

interface ToastOptions {
  severity: 'error' | 'success' | 'info' | 'warn';
  summary: string;
  detail: string;
  life?: number;
  sticky?: boolean;
}

export const addToast = ({
  severity,
  summary,
  detail,
  life = 5000,
  sticky = false,
}: ToastOptions): void => {
  const options: ReactToastOptions = {
    toastId: nanoid(),
    autoClose: sticky ? false : life,
    closeOnClick: true,
  };

  const toastMapping: Record<
    typeof severity,
    (message: string, opts: ReactToastOptions) => void
  > = {
    success: toast.success,
    error: toast.error,
    info: toast.info,
    warn: toast.warn,
  };

  toastMapping[severity](`${summary}: ${detail}`, options);
};
