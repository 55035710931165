import { FileTypes } from '@/utils/enums/FileTypes';

export const getFileIcon = (name: string | { path: string }) => {
  if (!name) return 'icon-[mdi-light--file]';

  const fileName = typeof name === 'object' && name.path ? name.path : name;

  if (typeof fileName !== 'string') {
    return 'icon-[mdi-light--file]';
  }

  const extensionIconMap: { [key: string]: string } = {
    jpg: 'icon-[mdi--file-image-outline]',
    jpeg: 'icon-[mdi--file-image-outline]',
    png: 'icon-[mdi--file-image-outline]',
    webp: 'icon-[mdi--file-image-outline]',
    pdf: 'icon-[ic--outline-picture-as-pdf]',
    doc: 'icon-[mdi--file-word-outline]',
    docx: 'icon-[mdi--file-word-outline]',
    mp3: 'icon-[material-symbols--audio-file-outline]',
    xls: 'icon-[mdi--file-excel-outline]',
    xlsx: 'icon-[mdi--file-excel-outline]',
    ppt: 'icon-[mdi--file-powerpoint-outline]',
    pptx: 'icon-[mdi--file-powerpoint-outline]',
  };

  const extension = fileName.split('.').pop()?.toLowerCase();

  return extensionIconMap[extension || ''] || 'icon-[mdi-light--file]';
};

export const getFormattedFileType = (fileType: string): FileTypes => {
  const fileTypeMap: { [key: string]: FileTypes } = {
    image: FileTypes.Image,
    audio: FileTypes.Audio,
  };

  return fileTypeMap[fileType] || FileTypes.Document;
};
