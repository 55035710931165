import { useState } from 'react';

export const useToggle = (
  isInitiallyOpen = false
): [boolean, (isOpen: boolean) => void] => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const toggleModal = (isOpened: boolean) => {
    setIsOpen(isOpened);
  };

  return [isOpen, toggleModal];
};
