import { PayloadAction, isRejectedWithValue } from '@reduxjs/toolkit';
import { addToast } from '@/utils/helpers/toast';

export const rtkQueryErrorLogger =
  () =>
  (next: any) =>
  (
    action: PayloadAction<{
      message: string;
      validations: { message: string }[];
    }>
  ) => {
    if (isRejectedWithValue(action)) {
      if (
        action.payload?.validations?.some((validation) =>
          Boolean(validation.message)
        )
      ) {
        action.payload?.validations?.forEach((validation) => {
          addToast({
            detail: validation?.message || 'Bir hata oluştu',
            summary: 'Hata',
            severity: 'error',
            life: 8000,
          });
        });
      } else {
        addToast({
          detail: action.payload?.message || 'Bir hata oluştu',
          summary: 'Hata',
          severity: 'error',
          life: 8000,
        });
      }
    }

    return next(action);
  };
