import {
  IChangeRoleRequest,
  IMyCampuses,
  IMyInfo,
  IMyPasswordRequest,
  IPasswordRequest,
  type IUpdateMyProfile,
} from './types';
import TAG_TYPES from '@/utils/constants/tagTypes';
import { IPageResponse } from '@/types/common.types';
import { baseApi } from '../baseApi';
import { getAccessToken } from '../axios-instance';

export const usersApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    changePassword: builder.mutation<unknown, IPasswordRequest>({
      query: (userParams) => ({
        url: `/users/${userParams.userId}/password`,
        data: { password: userParams.password },
        method: 'PUT',
      }),
      invalidatesTags: [TAG_TYPES.USER, TAG_TYPES.EMPLOYEE],
    }),
    myCampuses: builder.query<IPageResponse<IMyCampuses>, void>({
      query: () => ({
        url: `/users/my-campuses`,
        method: 'GET',
      }),
    }),
    myInfo: builder.query<IMyInfo, void>({
      query: () => ({
        url: `/users/my-info`,
        method: 'GET',
      }),
      providesTags: [TAG_TYPES.USER],
    }),
    changeMyPassword: builder.mutation<unknown, IMyPasswordRequest>({
      query: (userParams) => ({
        url: `/users/my-info/change-password`,
        data: { password: userParams.password },
        method: 'PUT',
      }),
    }),
    updateProfile: builder.mutation<unknown, IUpdateMyProfile>({
      query: (params) => ({
        url: `/users/my-info/profile`,
        data: {
          ...params,
        },
        method: 'PUT',
      }),
    }),
    changeRole: builder.mutation<unknown, IChangeRoleRequest>({
      query: ({ role }) => {
        return {
          url: `authentication/test/change-role`,
          method: 'POST',
          data: {
            role,
            accessToken: getAccessToken(),
          },
          withCredentials: true,
        };
      },
      invalidatesTags: [TAG_TYPES.USER],
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useMyCampusesQuery,
  useMyInfoQuery,
  useChangeRoleMutation,
  useUpdateProfileMutation,
  useChangeMyPasswordMutation,
} = usersApi;
