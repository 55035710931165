import { DropzoneOptions } from 'react-dropzone';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
} from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useUploadFileMutation } from '@/services/files';
import { FileTypes } from '@/utils/enums/FileTypes';
import { FolderType } from '@/services/files/types';
import CustomDropzone from '@/components/CustomDropzone';
import { getFormattedFileType } from '@/utils/helpers/uploader';

type FilePath = { path: string } | string;

const MAX_FILE_SIZE = 15_000_000;

export interface IControlledUploaderProps<T extends FieldValues>
  extends DropzoneOptions {
  className?: string;
  control: Control<T>;
  label?: string;
  multiple?: boolean;
  type?: FileTypes;
  name: Path<T>;
  wrapperClassName?: string;
  isStringArray?: boolean;
  folder?: FolderType;
}

const ControlledUploader = <T extends FieldValues>({
  className,
  control,
  label,
  multiple = false,
  name,
  wrapperClassName,
  isStringArray = true,
  folder,
  ...rest
}: IControlledUploaderProps<T>) => {
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];
  const [uploadingCount, setUploadingCount] = useState(0);
  const [uploadFile] = useUploadFileMutation();

  const isLoading = uploadingCount > 0;

  const converter = (filePath: FilePath) => {
    return isStringArray && typeof filePath !== 'string'
      ? filePath?.path
      : filePath;
  };

  const bulkConverter = (filePath: FilePath[]) => {
    return filePath.map(converter);
  };

  const handleBulkUploadChange = async (
    files: File[],
    existingFiles: File[]
  ) => {
    setUploadingCount(files.length);
    const uploadedFiles = await Promise.allSettled(
      files.map((file) => {
        const fileType = file.type.split('/')[0];
        const formattedType = getFormattedFileType(fileType);

        return uploadFile({
          file,
          type: formattedType,
          folder: folder || (currentPath as FolderType),
        }).unwrap();
      })
    );

    setUploadingCount((prev) => prev - files.length);

    const newFiles = uploadedFiles
      .filter((result) => result.status === 'fulfilled')
      .map((result) => (result as PromiseFulfilledResult<never>).value);

    return [...existingFiles, ...bulkConverter(newFiles)];
  };

  const handleChange =
    (field: ControllerRenderProps<T, Path<T>>) => async (event: File[]) => {
      // eslint-disable-next-line no-nested-ternary
      const existingFiles = Array.isArray(field.value)
        ? field.value
        : field.value
        ? [field.value]
        : [];

      if (multiple) {
        const updatedFiles = await handleBulkUploadChange(event, existingFiles);
        return field.onChange(updatedFiles);
      }

      const fileType = event[0].type.split('/')[0];
      const formattedType = getFormattedFileType(fileType);

      setUploadingCount(1);

      return uploadFile({
        file: event[0],
        type: formattedType,
        folder: folder || (currentPath as FolderType),
      })
        .unwrap()
        .then((res) => {
          const newValue = multiple
            ? [...existingFiles, converter(res)]
            : converter(res);
          field.onChange(newValue);
          setUploadingCount(0);
        });
    };

  const handleDelete = (
    field: ControllerRenderProps<T, Path<T>>,
    filePath: string
  ) => {
    if (multiple) {
      const currentFiles = Array.isArray(field.value) ? field.value : [];
      const updatedFiles = currentFiles?.filter(
        (file: string) => file !== filePath
      );
      return field.onChange(updatedFiles);
    }
    return field.onChange('');
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <div className={wrapperClassName}>
          {label && <label>{label}</label>}
          <CustomDropzone
            defaultValue={field.value}
            multiple={multiple}
            onChange={handleChange(field)}
            onChangeOverride
            className={classNames(
              { '!border-error ': fieldState.error },
              className
            )}
            loading={isLoading}
            maxSize={MAX_FILE_SIZE}
            handleDelete={(filePath: string) => handleDelete(field, filePath)}
            {...rest}
          />
          {fieldState.error ? (
            <small className="p-error">
              {fieldState.error?.message as string}
            </small>
          ) : (
            <small className="p-error">&nbsp;</small>
          )}
        </div>
      )}
    />
  );
};

export default ControlledUploader;
