import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { APIOptions, PrimeReactProvider, addLocale } from 'primereact/api';
import * as Sentry from '@sentry/react';
import { classNames } from 'primereact/utils';
import App from './App';
import { store } from './store';
import './index.css';
import '@/locales';
import 'primeicons/primeicons.css';

addLocale('tr', {
  firstDayOfWeek: 1,
  dayNames: [
    'Pazar',
    'Pazartesi',
    'Salı',
    'Çarşamba',
    'Perşembe',
    'Cuma',
    'Cumartesi',
  ],
  dayNamesShort: ['Paz', 'Pzt', 'Salı', 'Çrş', 'Prş', 'Cuma', 'Cts'],
  dayNamesMin: ['Paz', 'Pzt', 'Salı', 'Çrş', 'Prş', 'Cuma', 'Cts'],
  monthNames: [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ],
  monthNamesShort: [
    'Oca',
    'Şub',
    'Mar',
    'Nis',
    'May',
    'Haz',
    'Tem',
    'Ağu',
    'Eyl',
    'Eki',
    'Kas',
    'Ara',
  ],
  today: 'Bugün',
  clear: 'Temizle',
  emptyMessage: 'Kayıt bulunamadı',
});

const primeThemeValue: Partial<APIOptions> = {
  ripple: true,
  locale: 'tr',
  pt: {
    datatable: {
      loadingOverlay: {
        className: classNames(
          'fixed w-full h-full t-0 l-0 bg-gray-100/40',
          'transition duration-200',
          'absolute flex items-center justify-center z-2',
          'dark:bg-gray-950/40' // Dark Mode
        ),
      },
    },
  },
};

if (import.meta.env.VITE_ENV === 'prod') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event) {
      // we can add any add additioanl flags or context here
      return event;
    },
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <PrimeReactProvider value={primeThemeValue}>
      <App />
    </PrimeReactProvider>
  </Provider>
);
