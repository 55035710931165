import { createSlice } from '@reduxjs/toolkit';

export interface NavigationState {
  isSidebarOpened: boolean;
}

const initialState: NavigationState = {
  isSidebarOpened: localStorage.getItem('isSidebarOpened') === 'true',
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpened = !state.isSidebarOpened;
    },
  },
});

export const { toggleSidebar } = navigationSlice.actions;

export default navigationSlice.reducer;
