import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axios-instance';
import TAG_TYPES from '@/utils/constants/tagTypes';

export const baseApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: Object.values(TAG_TYPES),
  endpoints: () => ({}),
  keepUnusedDataFor: 0,
});
export default baseApi;
