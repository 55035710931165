import { clearAccessToken } from '@/services/axios-instance';

export const getCurrentPath = () => window.location.pathname;

export const navigateToSignin = () => {
  clearAccessToken();
  const signInUrl = '/signin';
  if (!getCurrentPath().includes(signInUrl)) {
    window.location.href = signInUrl;
  }
};

export const navigateToSelectCampus = () => {
  const selectCampusUrl = '/select-campus';
  if (!getCurrentPath().includes(selectCampusUrl)) {
    window.location.href = selectCampusUrl;
  }
};

export const navigateToDashboard = () => {
  const dashboardUrl = '/';

  if (getCurrentPath() !== '/') {
    window.location.href = dashboardUrl;
  }
};
