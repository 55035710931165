export interface IParseJwtReturn {
  exp: number;
  id: string;
  identityNumber: string;
  managementRoles: Array<number>;
  organizationID: string;
  roles: Array<RoleType>;
  isRoleChanged?: 'true' | '';
  currentCampusID: string;
}

export type RoleType = {
  campusID: string;
  values: Array<number>;
};

export const parseAuthToken = (token: string): IParseJwtReturn => {
  const base64Url = token?.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');

  if (base64) {
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  return {
    exp: 0,
    id: '',
    identityNumber: '',
    managementRoles: [],
    organizationID: '',
    roles: [],
    currentCampusID: '',
  };
};
