import { Suspense, useCallback, useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { sidebarRoutes, mainRoutes } from './Routes';

export default function AppRouter() {
  const [routeElements, setRouteElements] = useState(
    createBrowserRouter([...sidebarRoutes, ...mainRoutes])
  );

  const updateRoutes = useCallback(() => {
    setRouteElements(createBrowserRouter([...sidebarRoutes, ...mainRoutes]));
  }, []);

  useEffect(() => {
    const handleCampusChange = () => {
      updateRoutes();
    };

    window.addEventListener('campusIDChanged', handleCampusChange);

    return () => {
      window.removeEventListener('campusIDChanged', handleCampusChange);
    };
  }, [updateRoutes]);

  return (
    <Suspense
      fallback={
        <div className="w-screen h-screen flex items-center justify-center">
          <ProgressSpinner className="w-32 h-32" color="#ff4c51" />
        </div>
      }
    >
      <RouterProvider router={routeElements} />
    </Suspense>
  );
}
