import { IPageResponse } from '@/types/common.types';
import { IFollowUpNote } from '@/services/students/types';

const mockFollowUpNotesForClasses: IFollowUpNote[] = [
  {
    id: '11111111-2222-3333-4444-555555555555',
    lessonID: 'aaaa1111-bbbb-2222-cccc-3333dddd4444',
    lesson: {
      id: 'ffffaaaa-bbbb-cccc-dddd-eeeeffff1111',
      name: 'Tarih',
    },
    createdBy: 'Zeynep Aydın',
    title: 'Sınıf Projesi Durumu',
    description:
      'Sınıf projesi %80 oranında tamamlanmış. Grup çalışması oldukça başarılı.',
    isActive: true,
    createdAt: '2024-08-24T11:20:00Z',
  },
  {
    id: '22222222-3333-4444-5555-666666666666',
    lessonID: 'bbbb2222-cccc-3333-dddd-4444eeee5555',
    lesson: {
      id: 'ggggaaaa-bbbb-cccc-dddd-eeeeffff2222',
      name: 'Coğrafya',
    },
    createdBy: 'Ali Vural',
    title: 'Harita Çizim Çalışmaları',
    description:
      'Sınıfın çoğu harita çizimlerinde yüksek başarı göstermekte. Gözlem yetenekleri iyi.',
    isActive: true,
    createdAt: '2024-08-23T14:50:00Z',
  },
  {
    id: '33333333-4444-5555-6666-777777777777',
    lessonID: 'cccc3333-dddd-4444-eeee-5555ffff6666',
    lesson: {
      id: 'hhhhbbbb-cccc-dddd-eeee-ffffgggg3333',
      name: 'Felsefe',
    },
    createdBy: 'Ayhan Kaya',
    title: 'Tartışma Etkinlikleri',
    description:
      'Sınıf genelinde tartışma etkinliklerine yüksek katılım var. Eleştirel düşünme becerileri gelişmekte.',
    isActive: true,
    createdAt: '2024-08-22T09:35:00Z',
  },
  {
    id: '44444444-5555-6666-7777-888888888888',
    lessonID: 'dddd4444-eeee-5555-ffff-6666gggg7777',
    lesson: {
      id: 'iiiicccc-dddd-eeee-ffff-gggghhhh4444',
      name: 'Beden Eğitimi',
    },
    createdBy: 'Sevgi Erdem',
    title: 'Takım Sporları',
    description:
      'Sınıfın takım sporlarındaki uyumu çok iyi. Futbol ve voleybol etkinliklerinde liderlik görülüyor.',
    isActive: true,
    createdAt: '2024-08-21T15:10:00Z',
  },
  {
    id: '55555555-6666-7777-8888-999999999999',
    lessonID: 'eeee5555-ffff-6666-gggg-7777hhhh8888',
    lesson: {
      id: 'jjjjdddd-eeee-ffff-gggg-hhhhiiii5555',
      name: 'Müzik',
    },
    createdBy: 'Derya Kılıç',
    title: 'Enstrüman Çalışmaları',
    description:
      'Sınıfın büyük bir kısmı enstrüman çalışmalarına aktif katılım gösteriyor. Müzikal yetenekler gelişmekte.',
    isActive: true,
    createdAt: '2024-08-20T10:45:00Z',
  },
  {
    id: '66666666-7777-8888-9999-000000000000',
    lessonID: 'ffff6666-gggg-7777-hhhh-8888iiii9999',
    lesson: {
      id: 'kkkkffff-gggg-hhhh-iiii-jjjjkkkk6666',
      name: 'Kimya',
    },
    createdBy: 'Bülent Yılmaz',
    title: 'Deneysel Çalışmalar',
    description:
      'Kimya dersinde sınıfın deneysel çalışmalara ilgisi yüksek. Güvenlik önlemlerine dikkat ediliyor.',
    isActive: true,
    createdAt: '2024-08-19T13:30:00Z',
  },
  {
    id: '77777777-8888-9999-0000-111111111111',
    lessonID: 'gggg7777-hhhh-8888-iiii-9999jjjj0000',
    lesson: {
      id: 'llllgggg-hhhh-iiii-jjjj-kkkkllll7777',
      name: 'Edebiyat',
    },
    createdBy: 'Cemile Akın',
    title: 'Okuma ve Anlama',
    description:
      'Sınıf genelinde okuma ve anlama becerileri gelişiyor. Tartışmalara aktif katılım sağlanıyor.',
    isActive: true,
    createdAt: '2024-08-18T17:00:00Z',
  },
];

export const getDummyFollowUpNotesForClasses =
  (): IPageResponse<IFollowUpNote> => {
    return {
      items: mockFollowUpNotesForClasses,
      totalCount: 1,
      perPage: 100,
      pageCount: 1,
      page: 1,
    };
  };
