import axios from 'axios';

export const getAccessTokenByApi = (campusId?: string) => {
  const url = `${import.meta.env.VITE_API_URL}/authentication/access`;

  return axios.post<{
    data: {
      accessToken: string;
      permissions: string[];
    };
  }>(
    url,
    {},
    {
      headers: {
        isPublic: true,
      },
      withCredentials: true,
      params: campusId ? { campus_id: campusId } : {},
    }
  );
};
