import { classNames } from 'primereact/utils';
import { useCallback, useState, useEffect } from 'react';
import { Accept, DropzoneOptions, useDropzone } from 'react-dropzone';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getFileIcon } from '@/utils/helpers/uploader';
import FilePreview from '@/components/CustomDropzone/FilePreview';
import FileDialog from '@/components/CustomDropzone/FileDialog';
import { useToggle } from '@/utils/hooks/useToggle';
import { addToast } from '@/utils/helpers/toast';

interface DropzoneProps extends DropzoneOptions {
  accept?: Accept;
  className?: string;
  multiple?: boolean;
  onChange: (acceptedFiles: File[]) => void;
  placeholder?: string;
  preview?: boolean;
  defaultValue?: string | undefined | any[];
  maxSize?: number;
  minSize?: number;
  loading?: boolean;
  onChangeOverride?: boolean;
  handleDelete?: (field: any, filePath?: string) => void;
}

const CustomDropzone = ({
  accept = {
    'image/*': ['.png', '.jpg', '.jpeg'],
  },
  className,
  multiple = false,
  onChange,
  placeholder = `${
    multiple ? 'Dosyaları' : 'Dosyayı'
  } buraya sürükleyiniz veya seçmek için`,
  preview = true,
  defaultValue,
  maxSize = Infinity,
  minSize = 0,
  loading = false,
  onChangeOverride = false,
  handleDelete,
  ...rest
}: DropzoneProps) => {
  const [isModalOpen, setIsModalOpen] = useToggle(false);
  const [selectedFileURL, setSelectedFileURL] = useState<string | null>(null);

  const [previewFiles, setPreviewFiles] = useState<string[]>([]);

  useEffect(() => {
    if (defaultValue) {
      setPreviewFiles(
        Array.isArray(defaultValue) ? defaultValue : [defaultValue]
      );
    }
  }, [defaultValue]);

  const onDropAccepted = useCallback(
    (acceptedFiles: any) => {
      onChange(acceptedFiles as File[] | any);
      if (!onChangeOverride) {
        setPreviewFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      }
    },
    [onChange, onChangeOverride]
  );

  const validator = (file: File) => {
    let message = '';
    if (file.size > maxSize) {
      message = `Dosya boyutu ${maxSize / 1_000_000} MB den küçük olmalıdır.`;
    } else if (file.size < minSize) {
      message = `Dosya boyutu ${minSize / 1_000_000} MB dan büyük olmalıdır.`;
    }

    if (message) {
      addToast({
        severity: 'error',
        summary: 'Hata',
        detail: message,
      });
      return {
        code: 'file-size-restriction',
        message,
      };
    }

    return null;
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    accept,
    onDropAccepted,
    validator,
    ...rest,
  });

  const acceptedExtensions = Object.values(accept).flat();

  const renderPreview = () => {
    if (previewFiles.length === 0) {
      return null;
    }

    const handlePreviewClick = (docValue: string) => {
      setSelectedFileURL(docValue);
      setIsModalOpen(true);
    };

    const handleDeleteClick = (docValue: string) => {
      if (handleDelete) {
        handleDelete(docValue);
        setPreviewFiles((prevFiles) =>
          prevFiles.filter((file) => file !== docValue)
        );
      }
    };

    return previewFiles.map((doc: string) => {
      const docValue = doc;
      return (
        <FilePreview
          key={`${docValue}-preview`}
          docValue={docValue}
          handlePreviewClick={handlePreviewClick}
          handleDeleteClick={() => handleDeleteClick(docValue)}
          iconClassName={getFileIcon(docValue)}
        />
      );
    });
  };

  return (
    <div
      className={classNames(
        'flex flex-col items-center mt-2 justify-center rounded-md min-w-36 min-h-36 p-4 border border-gray-300 dark:border-[#424b57]',
        className
      )}
    >
      <div
        {...getRootProps()}
        className="relative flex flex-col items-center justify-center w-full min-h-36 py-8 p-4 border border-dashed rounded-md border-gray-300 hover:border-gray-400 dark:bg-gray-800 bg-gray-50 hover:transition-colors hover:bg-gray-100/80 duration-500 cursor-pointer group dark:border-[#424b57]"
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center">
          <span className="size-20 flex items-center justify-center rounded-full bg-gray-200 dark:bg-gray-700 dark:text-gray-300 my-5">
            <i className="pi pi-upload text-3xl" />
          </span>
          <ul className="flex flex-col *:list-disc *:list-inside px-4 lg:mx-auto">
            <li>
              {placeholder}{' '}
              <span className="underline font-semibold">tıklayınız</span>
            </li>
            <li>
              Maksimum {maxSize / 1_000_000} MB dosya kabul edilir
              {acceptedExtensions.length > 0 && <br />}
            </li>
            <li>
              Kabul edilen dosya uzantıları: {acceptedExtensions.join(', ')}
            </li>
          </ul>
        </div>
        {loading && (
          <div className="absolute z-20 backdrop-blur-lg w-full h-full flex items-center justify-center">
            <ProgressSpinner className="w-32 h-32" color="#ff4c51" />
          </div>
        )}
      </div>
      {preview && previewFiles.length > 0 && (
        <div className="w-full mt-4 flex flex-wrap gap-2">
          {renderPreview()}
        </div>
      )}

      {selectedFileURL && (
        <FileDialog
          isModalOpen={isModalOpen}
          toggleModal={setIsModalOpen}
          selectedFileURL={selectedFileURL}
        />
      )}
    </div>
  );
};

export default CustomDropzone;
