import { FC, PropsWithChildren, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfirmDialog } from 'primereact/confirmdialog';
import AppRouter from './routes';
import { getAccessToken, setAccessToken } from './services/axios-instance';
import { getAccessTokenByApi } from './services/authentication/accessToken';
import {
  navigateToDashboard,
  navigateToSignin,
} from './utils/helpers/navigate';
import { isMobile } from './utils/helpers/common';
import { parseAuthToken } from './utils/helpers/token';
import { getCampusId } from '@/utils/helpers/campus';

const AppContainer: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);

  const setSentryContext = () => {
    const parsedAuthToken = parseAuthToken(getAccessToken());

    Sentry.setContext('userInfo', {
      organizationID: parsedAuthToken.organizationID,
      roles: parsedAuthToken.roles,
      managementRoles: parsedAuthToken.managementRoles,
      selectedCampus: localStorage.getItem('E12_SELECTED_CAMPUS') || '',
    });
  };

  const fetchAccessToken = () => {
    if (!getAccessToken()) {
      getAccessTokenByApi()
        .then((resp) => {
          if (resp.data.data.accessToken) {
            setAccessToken(
              resp.data.data.accessToken,
              resp.data.data.permissions
            );

            const isSigninPage = window.location.pathname === '/signin';

            if (isSigninPage) {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              getCampusId() ? navigateToDashboard() : navigateToSignin();
            }
            setLoading(false);
          }
        })
        .catch(() => {
          navigateToSignin();
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchAccessToken();
    setSentryContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Buraya full page loader gelecek. -Berk
  if (loading) return null;

  return <div>{children}</div>;
};

// index asset error fix
window.addEventListener('vite:preloadError', () => {
  if (!isMobile) {
    window.location.reload();
  }
});

function App() {
  // eslint-disable-next-line no-console
  // console.log('mode :>> ', import.meta.env.VITE_ENV);

  return (
    <AppContainer>
      <ToastContainer />
      <AppRouter />
      <ConfirmDialog />
    </AppContainer>
  );
}

export default App;
