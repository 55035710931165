import { IStudent } from '@/services/students/types';

export const normalizeStudentLabel = (student: IStudent): string => {
  return `${student.firstname} ${student.lastname}`;
};

export const normalizedStudentData = (studentData: IStudent[]) => {
  return studentData.map((student) => ({
    name: normalizeStudentLabel(student),
    id: student.id,
  }));
};
