import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface IProps<T extends FieldValues> extends DropdownProps {
  control: Control<T>;
  label?: string;
  name: Path<T>;
  wrapperClassName?: string;
  isFilter?: boolean;
}

const ControlledDropdown = <T extends FieldValues & { loading?: boolean }>({
  control,
  label,
  name,
  wrapperClassName,
  isFilter = true,
  ...inputProps
}: IProps<T> & { loading?: boolean }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState, formState }) => (
        <div className={wrapperClassName}>
          {label && (
            <label
              htmlFor={label}
              className={classNames({ 'p-error': formState.errors.value })}
            >
              {label}
            </label>
          )}
          <Dropdown
            optionLabel="name"
            optionValue="id"
            {...field}
            onChange={(e) => field.onChange(e.target.value)}
            emptyMessage="Veri bulunamadı"
            className={classNames(
              { 'p-invalid ': fieldState.error },
              'w-full p-inputtext-sm mt-2',
              inputProps.className
            )}
            filter={isFilter}
            showClear
            {...inputProps}
          />
          {fieldState.error ? (
            <small className="p-error">
              {fieldState.error?.message as string}
            </small>
          ) : (
            <small className="p-error">&nbsp;</small>
          )}
        </div>
      )}
    />
  );
};

export default ControlledDropdown;
