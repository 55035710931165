import {
  ICreateStudentParams,
  IStudentDetailParams,
  IStudentsParams,
  IStudent,
  IUpdatesStudentParams,
  type IParent,
} from './types';
import TAG_TYPES from '@/utils/constants/tagTypes';
import { baseApi } from '../baseApi';
import { GUID, IPageResponse } from '@/types/common.types';

export const studentsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    students: builder.query<IPageResponse<IStudent[]>, IStudentsParams>({
      query: ({ campusID, ...studentParams }) => ({
        url: `/students`,
        params: studentParams,
        headers: {
          CampusID: campusID,
        },
      }),
      providesTags: [TAG_TYPES.STUDENTS],
    }),
    createStudent: builder.mutation<unknown, ICreateStudentParams>({
      query: (params) => ({
        url: `/students`,
        method: 'POST',
        data: params,
        headers: {
          CampusID: params.campusID,
        },
      }),
      invalidatesTags: [TAG_TYPES.STUDENTS],
    }),
    getStudentById: builder.query<IStudent, IStudentDetailParams>({
      query: ({ campusID, studentID }) => ({
        url: `/students/${studentID}`,
        headers: {
          CampusID: campusID,
        },
      }),
      providesTags: [TAG_TYPES.STUDENTS],
    }),
    updateStudent: builder.mutation<unknown, IUpdatesStudentParams>({
      query: ({ campusID, studentID, ...studentParams }) => ({
        url: `/students/${studentID}`,
        method: 'PUT',
        data: studentParams,
        headers: {
          CampusID: campusID,
        },
      }),
      invalidatesTags: [TAG_TYPES.STUDENTS],
    }),
    deleteStudent: builder.mutation<unknown, { studentID: GUID }>({
      query: ({ studentID }) => ({
        url: `/students/${studentID}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPES.STUDENTS],
    }),
    updateStudentStatus: builder.mutation<
      IStudent,
      { studentID: GUID; isActive: boolean }
    >({
      query: ({ isActive, studentID }) => ({
        url: `students/${studentID}/status`,
        method: 'PATCH',
        data: { isActive },
      }),
      invalidatesTags: [{ type: TAG_TYPES.STUDENTS }],
    }),
    getParentByIdentityNumber: builder.query<
      IParent,
      { campusID: GUID; identityNumber: string }
    >({
      query: ({ campusID, identityNumber }) => ({
        url: `students/parent/${identityNumber}`,
        headers: {
          CampusID: campusID,
        },
      }),
      providesTags: [TAG_TYPES.STUDENTS],
    }),
  }),
});

export const {
  useStudentsQuery,
  useLazyStudentsQuery,
  useCreateStudentMutation,
  useGetStudentByIdQuery,
  useLazyGetStudentByIdQuery,
  useUpdateStudentMutation,
  useDeleteStudentMutation,
  useUpdateStudentStatusMutation,
  useLazyGetParentByIdentityNumberQuery,
} = studentsApi;
