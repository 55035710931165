import { GUID, IPageResponse } from '@/types/common.types';
import { baseApi } from '../baseApi';
import {
  ILessonParams,
  ILesson,
  ICreateLessonParams,
  IUpdateLessonParams,
} from './types';
import TAG_TYPES from '@/utils/constants/tagTypes';

const lessonsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    lessons: builder.query<IPageResponse<ILesson>, ILessonParams>({
      query: ({ campusID, ...params }) => ({
        url: `lessons`,
        params,
        headers: {
          CampusID: campusID,
        },
      }),
      providesTags: (result, error, args) => [
        { type: TAG_TYPES.LESSONS, id: args.campusID },
      ],
    }),
    employeeLessons: builder.query<IPageResponse<ILesson>, ILessonParams>({
      query: ({ campusID, ...params }) => ({
        url: `v2/lessons`,
        params,
        headers: {
          CampusID: campusID,
        },
      }),
      providesTags: (result, error, args) => [
        { type: TAG_TYPES.LESSONS, id: args.campusID },
      ],
    }),
    lessonById: builder.query<ILesson, { campusID?: GUID; lessonID: GUID }>({
      query: ({ campusID, lessonID }) => ({
        url: `lessons/${lessonID}`,
        headers: {
          CampusID: campusID,
        },
      }),
      providesTags: (result, error, args) => [
        { type: TAG_TYPES.LESSONS, id: args.campusID },
      ],
    }),
    createLesson: builder.mutation<ILesson, ICreateLessonParams>({
      query: ({ campusID, ...params }) => ({
        url: `lessons`,
        method: 'POST',
        data: params,
        headers: {
          CampusID: campusID,
        },
      }),
      invalidatesTags: [{ type: TAG_TYPES.LESSONS }],
    }),
    updateLesson: builder.mutation<ILesson, IUpdateLessonParams>({
      query: ({ campusID, lessonID, ...params }) => ({
        url: `lessons/${lessonID}`,
        method: 'PUT',
        data: params,
        headers: {
          CampusID: campusID,
        },
      }),
      invalidatesTags: [{ type: TAG_TYPES.LESSONS }],
    }),
    deleteLesson: builder.mutation<
      unknown,
      { campusID?: GUID; lessonID: GUID }
    >({
      query: ({ campusID, lessonID }) => ({
        url: `lessons/${lessonID}`,
        method: 'DELETE',
        headers: {
          CampusID: campusID,
        },
      }),
      invalidatesTags: [{ type: TAG_TYPES.LESSONS }],
    }),
    updateLessonStatus: builder.mutation<
      ILesson,
      { lessonID: GUID; isActive: boolean }
    >({
      query: ({ isActive, lessonID }) => ({
        url: `lessons/${lessonID}/status`,
        method: 'PATCH',
        data: { isActive },
      }),
      invalidatesTags: [{ type: TAG_TYPES.LESSONS }],
    }),
  }),
});

export const {
  useLessonsQuery,
  useEmployeeLessonsQuery,
  useLessonByIdQuery,
  useLazyLessonByIdQuery,
  useLazyLessonsQuery,
  useCreateLessonMutation,
  useUpdateLessonMutation,
  useDeleteLessonMutation,
  useUpdateLessonStatusMutation,
} = lessonsApi;
