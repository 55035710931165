import { Button } from 'primereact/button';

const FilePreview = ({
  docValue,
  handlePreviewClick,
  handleDeleteClick,
  iconClassName,
}: {
  docValue: string;
  handlePreviewClick: (docValue: string) => void;
  handleDeleteClick: () => void;
  iconClassName: string;
}) => {
  return (
    <div
      key="preview"
      className="items-center justify-between p-4 border rounded-md bg-gray-50 w-full dark:bg-gray-800 border-solid border-gray-200 dark:border-[#424b57] flex flex-col md:flex-row gap-4"
    >
      <span className="flex items-center gap-4">
        <i className={`${iconClassName} size-8 shrink-0`} />
        {docValue}
      </span>
      <div className="flex flex-row mt-2 gap-2">
        <Button
          type="button"
          label="Önizle"
          icon="pi pi-eye"
          severity="warning"
          size="small"
          text
          onClick={() => handlePreviewClick(docValue)}
        />
        <Button
          type="button"
          label="Sil"
          icon="pi pi-trash"
          severity="danger"
          size="small"
          text
          onClick={handleDeleteClick}
        />
      </div>
    </div>
  );
};

export default FilePreview;
